// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Lexend+Deca);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html,
body {
  height: 100vh;
  margin: 0;
  background-color: #d5c093;
  font-family: "Lexend Deca";
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  text-align: center;
  max-height: 100vh;
  padding: 1rem; /* adds slight padding */
  box-sizing: border-box; /* includes padding in height calculation */
}

.matsogmorten {
  display: block;
  margin: 0 auto;
  width: 20%; /* Reduce width to fit better */
  max-width: 100%;
  height: auto; /* Keeps the aspect ratio of the image */
}

.lovund {
  display: block;
  margin: 0 auto;
  width: 20%; /* Reduce width to fit better */
  max-width: 100%;
  height: auto; /* Keeps the aspect ratio of the image */
}

@media (max-width: 600px) {
  .matsogmorten {
    width: 70%; /* smaller image width for mobile screens */
  }

  .lovund {
    width: 50%;
  }

  .container {
    padding: 0.5rem; /* less padding on smaller screens */
  }
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA;;EAEE,aAAa;EACb,SAAS;EACT,yBAAyB;EACzB,0BAA0B;EAC1B,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,aAAa,EAAE,wBAAwB;EACvC,sBAAsB,EAAE,2CAA2C;AACrE;;AAEA;EACE,cAAc;EACd,cAAc;EACd,UAAU,EAAE,+BAA+B;EAC3C,eAAe;EACf,YAAY,EAAE,wCAAwC;AACxD;;AAEA;EACE,cAAc;EACd,cAAc;EACd,UAAU,EAAE,+BAA+B;EAC3C,eAAe;EACf,YAAY,EAAE,wCAAwC;AACxD;;AAEA;EACE;IACE,UAAU,EAAE,2CAA2C;EACzD;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,eAAe,EAAE,oCAAoC;EACvD;AACF","sourcesContent":["@import url(\"https://fonts.googleapis.com/css?family=Lexend+Deca\");\n\nhtml,\nbody {\n  height: 100vh;\n  margin: 0;\n  background-color: #d5c093;\n  font-family: \"Lexend Deca\";\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.container {\n  text-align: center;\n  max-height: 100vh;\n  padding: 1rem; /* adds slight padding */\n  box-sizing: border-box; /* includes padding in height calculation */\n}\n\n.matsogmorten {\n  display: block;\n  margin: 0 auto;\n  width: 20%; /* Reduce width to fit better */\n  max-width: 100%;\n  height: auto; /* Keeps the aspect ratio of the image */\n}\n\n.lovund {\n  display: block;\n  margin: 0 auto;\n  width: 20%; /* Reduce width to fit better */\n  max-width: 100%;\n  height: auto; /* Keeps the aspect ratio of the image */\n}\n\n@media (max-width: 600px) {\n  .matsogmorten {\n    width: 70%; /* smaller image width for mobile screens */\n  }\n\n  .lovund {\n    width: 50%;\n  }\n\n  .container {\n    padding: 0.5rem; /* less padding on smaller screens */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
