import React from "react";
import matsogmorten from "./matsogmorten.png";
import lovund from "./lovund.png";

function App() {
  return (
    <>
      <div className="container">
        <h1>MATS OG MORTEN</h1>
        <img className="matsogmorten" src={matsogmorten} alt="matsogmorten" />
        <h3>27.-29.06.25</h3>
        <p>
          Svar innen 10. januar via ditt foretrukne medium, eventuelt på
          47806373 eller 90270599
        </p>

        <h5>Mer informasjon kommer!</h5>
        <img className="lovund" src={lovund} alt="lovund" />
      </div>
    </>
  );
}

export default App;
